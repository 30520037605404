var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-12" },
    [
      _c(
        "vs-row",
        _vm._l(_vm.proposta.Beneficiaries, function(item, index) {
          return _c(
            "vs-card",
            { key: index, staticClass: "beneficiary-card" },
            [
              _c("div", { staticClass: "info-content" }, [
                _c("span", { staticClass: "info-container" }, [
                  _c("p", { staticClass: "title-info" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          item.CpfCnpj.length > 11 ? "Razão Social" : "Nome"
                        ) +
                        " : " +
                        _vm._s(item.Name) +
                        " \n          "
                    )
                  ]),
                  item.NomeSocial
                    ? _c("p", { staticClass: "title-info" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              item.CpfCnpj.length > 11
                                ? "Nome Fantasia"
                                : "Nome Social"
                            ) +
                            "  : " +
                            _vm._s(item.NomeSocial) +
                            " \n          "
                        )
                      ])
                    : _vm._e(),
                  _c("p", { staticClass: "subtitle-info" }, [
                    _vm._v(_vm._s(_vm.$utils.maskDocument(item.CpfCnpj)))
                  ])
                ]),
                _c("p", { staticClass: "percent-info" }, [
                  _vm._v(_vm._s(item.PercentageAmountValue) + "%")
                ])
              ]),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "vs-row",
                    { attrs: { "vs-justify": "flex-end" } },
                    [
                      _c("vs-button", {
                        staticClass: "delete-beneficiary-btn",
                        attrs: {
                          type: "filled",
                          color: "danger",
                          icon: "delete_outline"
                        },
                        on: {
                          click: function($event) {
                            return _vm.removeBeneficiary(item)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        }),
        1
      ),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full justify-end flex" },
          [
            _c(
              "vs-button",
              {
                staticClass: "p-0",
                attrs: { type: "flat" },
                on: {
                  click: function($event) {
                    _vm.active = true
                  }
                }
              },
              [
                _c("i", { staticClass: "onpoint-plus icon-font" }),
                _vm._v(" Adicionar beneficiário\n      ")
              ]
            )
          ],
          1
        )
      ]),
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              return [
                _c(
                  "popup-base",
                  {
                    attrs: {
                      showModal: _vm.active,
                      size: "lg",
                      id: "beneficiary-details-modal",
                      title: "Adicionar beneficiário",
                      "button-close-hidden": false
                    },
                    on: {
                      close: function($event) {
                        _vm.active = false
                      }
                    }
                  },
                  [
                    _c(
                      "template",
                      { slot: "content" },
                      [
                        _c(
                          "vs-row",
                          [
                            _c(
                              "vs-col",
                              { staticClass: "w-full" },
                              [
                                _c(
                                  "multiselect",
                                  {
                                    staticClass: "w-full",
                                    attrs: {
                                      id: "buscar-beneficiario",
                                      label: "Name",
                                      "open-direction": "bottom",
                                      placeholder: _vm.allowedPF
                                        ? "Digite o CPF, CNPJ ou Razão Social para buscar um Beneficiário"
                                        : "Digite o CNPJ ou Razão Social para buscar um Beneficiário",
                                      options: _vm.beneficiariesResult || [],
                                      searchable: true,
                                      loading: _vm.isLoading,
                                      "internal-search": false,
                                      "clear-on-select": false,
                                      "options-limit": 300,
                                      limit: 3,
                                      "max-height": 600,
                                      "show-no-options": false,
                                      "show-no-results": true,
                                      "hide-selected": true,
                                      "select-label": ""
                                    },
                                    on: { "search-change": _vm.getBeneficiary },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "option",
                                          fn: function(props) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "option__desc",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.beneficiaryValidation(
                                                        props.option
                                                          .DocumentNumber
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "option__title"
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.option.Name
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm.$utils.maskDocument(
                                                    props.option.DocumentNumber
                                                  )
                                                    ? _c(
                                                        "p",
                                                        { staticClass: "mt-3" },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm.$utils.maskDocument(
                                                                  props.option
                                                                    .DocumentNumber
                                                                )
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  props.option.Description
                                                    ? _c(
                                                        "p",
                                                        { staticClass: "mt-3" },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                props.option
                                                                  .Description
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "clear",
                                          fn: function(props) {
                                            return [
                                              _c("div", {
                                                staticClass:
                                                  "multiselect__clear",
                                                on: {
                                                  mousedown: function($event) {
                                                    $event.preventDefault()
                                                    $event.stopPropagation()
                                                    return _vm.clearAll(
                                                      props.search
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.cpfCnpjRazaoSocial,
                                      callback: function($$v) {
                                        _vm.cpfCnpjRazaoSocial = $$v
                                      },
                                      expression: "cpfCnpjRazaoSocial"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "noResult" },
                                        slot: "noResult"
                                      },
                                      [
                                        _vm._v(
                                          "Não foi encontrado nenhum beneficiário na base"
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        !!(_vm.beneficiary || {}).UniqueId
                          ? [
                              _c(
                                "vs-row",
                                [
                                  _c("vs-col", { attrs: { "vs-w": "12" } }, [
                                    _c("h3", {}, [
                                      _vm._v("Dados do beneficiário")
                                    ])
                                  ]),
                                  _c(
                                    "vs-col",
                                    { attrs: { "vs-lg": "6", "vs-sm": "12" } },
                                    [
                                      _c("vs-input", {
                                        staticClass: "w-full",
                                        attrs: {
                                          disabled: "",
                                          label:
                                            _vm.beneficiary.Entity == "F"
                                              ? "CPF"
                                              : "CNPJ",
                                          value: _vm.$utils.maskDocument(
                                            _vm.beneficiary.CpfCnpj
                                          ),
                                          size: "large"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-col",
                                    { attrs: { "vs-lg": "6", "vs-sm": "12" } },
                                    [
                                      _vm.editName
                                        ? _c(
                                            "div",
                                            { staticClass: "group-edit" },
                                            [
                                              _c("vs-input", {
                                                staticClass: "w-full",
                                                staticStyle: {
                                                  "padding-right":
                                                    "35px !important"
                                                },
                                                attrs: {
                                                  disabled: "",
                                                  label:
                                                    _vm.beneficiary.Entity ==
                                                    "F"
                                                      ? "Nome completo"
                                                      : "Razão social",
                                                  size: "large"
                                                },
                                                model: {
                                                  value: _vm.beneficiary.Name,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.beneficiary,
                                                      "Name",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "beneficiary.Name"
                                                }
                                              }),
                                              _vm.podeHabilitarCadastroNomeSocialBeneficiario
                                                ? _c("i", {
                                                    staticClass:
                                                      "onpoint-pencil-simple-line icon-font  float-right text-lg ",
                                                    staticStyle: {
                                                      "margin-left": "5px",
                                                      color: "#083d63",
                                                      cursor: "pointer",
                                                      position: "absolute",
                                                      top: "35px",
                                                      right: "45px"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        _vm.editName = false
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "group-edit" },
                                            [
                                              _c("vs-input", {
                                                staticClass: "w-full",
                                                attrs: {
                                                  label:
                                                    _vm.beneficiary.Entity ==
                                                    "F"
                                                      ? "Nome Social"
                                                      : "Nome Fantasia",
                                                  size: "large"
                                                },
                                                on: {
                                                  blur: function($event) {
                                                    return _vm.insertName()
                                                  }
                                                },
                                                model: {
                                                  value: _vm.nameEdit,
                                                  callback: function($$v) {
                                                    _vm.nameEdit = $$v
                                                  },
                                                  expression: "nameEdit"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                    ]
                                  )
                                ],
                                1
                              ),
                              ((_vm.beneficiary || {}).Locations || []).length
                                ? _c(
                                    "vs-row",
                                    [
                                      _c(
                                        "vs-col",
                                        { attrs: { "vs-w": "12" } },
                                        [
                                          _c("vs-input", {
                                            staticClass: "w-full",
                                            attrs: {
                                              disabled: "",
                                              label: "Endereço",
                                              value:
                                                (
                                                  _vm.beneficiary.Locations.filter(
                                                    function(x) {
                                                      return x.IsMain
                                                    }
                                                  )[0] || {}
                                                ).Address ||
                                                (
                                                  _vm.beneficiary.Locations.filter(
                                                    function(x) {
                                                      return x.Address
                                                    }
                                                  )[0] || {}
                                                ).Address,
                                              size: "large"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _c("form-endereco", {
                                    attrs: {
                                      dadosEndereco: _vm.beneficiary.NewAddress
                                    },
                                    on: {
                                      updateValue: function($event) {
                                        _vm.beneficiary.NewAddress = $event
                                      }
                                    }
                                  }),
                              _c(
                                "vs-row",
                                [
                                  _c(
                                    "vs-col",
                                    { attrs: { "vs-lg": "6", "vs-sm": "12" } },
                                    [
                                      _c("ValidationProvider", {
                                        attrs: {
                                          name: "% do seguro",
                                          rules: {
                                            required: true,
                                            minNumber: 1,
                                            maxNumber: _vm.maxPercent
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("vs-input", {
                                                    staticClass: "w-full",
                                                    attrs: {
                                                      label: "% do seguro",
                                                      max: 100,
                                                      min: 1,
                                                      size: "large"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.beneficiary
                                                          .PercentageAmountValue,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.beneficiary,
                                                          "PercentageAmountValue",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "beneficiary.PercentageAmountValue"
                                                    }
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: errors[0],
                                                          expression:
                                                            "errors[0]"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "text-danger text-sm"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(errors[0]) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    ),
                    _c(
                      "template",
                      { slot: "footer" },
                      [
                        _c(
                          "vs-row",
                          { staticClass: "p-0" },
                          [
                            _c(
                              "vs-col",
                              {
                                staticClass: "justify-between flex",
                                attrs: { "vs-w": "12" }
                              },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "ml-4 default",
                                    attrs: { type: "border" },
                                    on: {
                                      click: function($event) {
                                        return _vm.closeModal()
                                      }
                                    }
                                  },
                                  [_vm._v("Cancelar\n            ")]
                                ),
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "ml-4",
                                    attrs: {
                                      color: "success",
                                      type: "filled",
                                      disabled: invalid
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.addBeneficiary()
                                      }
                                    }
                                  },
                                  [_vm._v("Adicionar\n            ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }