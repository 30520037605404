var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var invalid = ref.invalid
            return [
              _c(
                "div",
                { staticClass: "vx-row px-8 relative" },
                [
                  _c(
                    "section",
                    { staticClass: "vx-col w-8/12 pr-10" },
                    [
                      _vm._t("complementoConteudoProposta"),
                      _c("div", { staticClass: "vx-row px-2" }, [
                        _c(
                          "div",
                          { staticClass: "w-1/2 px-2" },
                          [
                            _c("p", { staticClass: "flex font-semibold" }, [
                              _vm._v("Grupo modalidades")
                            ]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Grupo de modalidades",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-select", {
                                          staticClass: "w-full",
                                          attrs: {
                                            label: "Name",
                                            options: _vm.modalidades,
                                            "show-no-options": false,
                                            "show-no-results": false,
                                            value: _vm.selectedGrupo,
                                            placeholder: "Selecione uma opção",
                                            dir: _vm.$vs.rtl ? "rtl" : "ltr"
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.grupoModalidadeSelecionado(
                                                _vm.selectedGrupo
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.selectedGrupo,
                                            callback: function($$v) {
                                              _vm.selectedGrupo = $$v
                                            },
                                            expression: "selectedGrupo"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ],
                                            staticClass: "text-danger text-sm"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(errors[0]) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "w-1/2 px-2" },
                          [
                            _c("p", { staticClass: "flex font-semibold" }, [
                              _vm._v(
                                "\n            Modalidade principal\n            "
                              )
                            ]),
                            !(_vm.selectedGrupo || {}).Id
                              ? _c("p", { staticClass: "mt-4" }, [
                                  _vm._v(
                                    "\n            Selecione um grupo de modalidades\n          "
                                  )
                                ])
                              : _vm._e(),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Modalidade principal",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        (_vm.selectedGrupo || {}).Id
                                          ? _c("v-select", {
                                              staticClass: "w-full select",
                                              attrs: {
                                                label: "Name",
                                                "show-no-options": false,
                                                options:
                                                  _vm.modalidadePrincipal,
                                                placeholder:
                                                  "Selecione uma opção",
                                                dir: _vm.$vs.rtl ? "rtl" : "ltr"
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.modalidadeSelecionada(
                                                    _vm.selectedModalidadePrincipal
                                                  )
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.selectedModalidadePrincipal,
                                                callback: function($$v) {
                                                  _vm.selectedModalidadePrincipal = $$v
                                                },
                                                expression:
                                                  "selectedModalidadePrincipal"
                                              }
                                            })
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ],
                                            staticClass: "text-danger text-sm"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(errors[0]) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "vx-row mt-5 px-2" }, [
                        _c(
                          "div",
                          { staticClass: "w-1/4 pl-2 pr-1" },
                          [
                            _c("p", { staticClass: "flex font-semibold" }, [
                              _vm._v(
                                "\n            Importância segurada\n            "
                              )
                            ]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Importância segurada",
                                rules:
                                  "required|minInsurancePremium:" +
                                  (_vm.selectedModalidadePrincipal || {})
                                    .MinInsurancePremium +
                                  "," +
                                  (_vm.selectedModalidadePrincipal || {}).Name +
                                  "," +
                                  _vm.percLimit +
                                  "|maxInsurancePremium:" +
                                  (_vm.selectedModalidadePrincipal || {})
                                    .MaxInsuredAmountValue +
                                  "," +
                                  (_vm.selectedModalidadePrincipal || {}).Name
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("currency-input", {
                                          staticClass:
                                            "w-full vs-inputx vs-input--input large hasValue",
                                          on: {
                                            blur: function($event) {
                                              return _vm.calculoValorPremio(1)
                                            }
                                          },
                                          model: {
                                            value: _vm.importanciaSegurada,
                                            callback: function($$v) {
                                              _vm.importanciaSegurada = $$v
                                            },
                                            expression: "importanciaSegurada"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ],
                                            staticClass: "text-danger text-sm"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(errors[0]) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "w-1/4 pl-3 pr-2" },
                          [
                            _c("p", { staticClass: "font-semibold" }, [
                              _vm._v("Inicio vigência")
                            ]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Data de início",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("datepicker", {
                                          staticClass: "w-full",
                                          attrs: {
                                            language: _vm.ptBR,
                                            format: "dd/MM/yyyy",
                                            "disabled-dates": _vm.disabledDatesInicio()
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.calculoDiasVigenciaPrincipal()
                                            }
                                          },
                                          model: {
                                            value: _vm.vigenciaInicioPrincipal,
                                            callback: function($$v) {
                                              _vm.vigenciaInicioPrincipal = $$v
                                            },
                                            expression:
                                              "vigenciaInicioPrincipal"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ],
                                            staticClass: "text-danger text-sm"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(errors[0]) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "w-1/4 px-2" },
                          [
                            _c("p", { staticClass: "font-semibold" }, [
                              _vm._v("Prazo dias")
                            ]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Prazo em dias",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: { size: "large" },
                                          on: {
                                            change: function($event) {
                                              return _vm.calculoDataFimVigenciaPrincipal()
                                            }
                                          },
                                          model: {
                                            value: _vm.vigenciaDiasPrincipal,
                                            callback: function($$v) {
                                              _vm.vigenciaDiasPrincipal = _vm._n(
                                                $$v
                                              )
                                            },
                                            expression: "vigenciaDiasPrincipal"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ],
                                            staticClass: "text-danger text-sm"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(errors[0]) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "w-1/4 px-2" },
                          [
                            _c("p", { staticClass: "font-semibold" }, [
                              _vm._v("Final vigência")
                            ]),
                            _c("ValidationProvider", {
                              attrs: { name: "Data fim", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _vm.isDisabledEnd
                                          ? _c("vs-input", {
                                              staticClass: "w-full",
                                              attrs: {
                                                size: "large",
                                                disabled: "true"
                                              },
                                              model: {
                                                value: _vm.vigenciaFimPrincipal,
                                                callback: function($$v) {
                                                  _vm.vigenciaFimPrincipal = $$v
                                                },
                                                expression:
                                                  "vigenciaFimPrincipal"
                                              }
                                            })
                                          : _c("datepicker", {
                                              staticClass: "w-full",
                                              attrs: {
                                                language: _vm.ptBR,
                                                format: "dd/MM/yyyy",
                                                "disabled-dates": _vm.disabledDatesFim()
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.calculoDiasVigenciaPrincipal()
                                                }
                                              },
                                              model: {
                                                value: _vm.vigenciaFimPrincipal,
                                                callback: function($$v) {
                                                  _vm.vigenciaFimPrincipal = $$v
                                                },
                                                expression:
                                                  "vigenciaFimPrincipal"
                                              }
                                            }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  errors[0] || _vm.showErroData,
                                                expression:
                                                  "errors[0] || showErroData"
                                              }
                                            ],
                                            staticClass: "text-danger text-sm"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  errors[0] || _vm.erroData
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ]),
                      (_vm.selectedModalidadePrincipal || {})
                        .HasComplementaryModality
                        ? [
                            _c("div", { staticClass: "vx-row mt-8 pl-2" }, [
                              _c(
                                "div",
                                { staticClass: "w-1/2 px-2" },
                                [
                                  _c("p", { staticClass: "font-semibold" }, [
                                    _vm._v("Modalidade complementar")
                                  ]),
                                  _c("ValidationProvider", {
                                    attrs: { name: "Modalidade complementar" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _vm.isDisabledComplementary
                                                ? _c("vs-input", {
                                                    staticClass: "w-full",
                                                    attrs: {
                                                      size: "large",
                                                      value:
                                                        "Aguardando modalidade principal...",
                                                      disabled: "true"
                                                    }
                                                  })
                                                : _c("v-select", {
                                                    staticClass: "w-full",
                                                    attrs: {
                                                      label: "Name",
                                                      options:
                                                        _vm.modalidadeComplementar,
                                                      "show-no-results": false,
                                                      placeholder:
                                                        "Selecione um opção",
                                                      dir: _vm.$vs.rtl
                                                        ? "rtl"
                                                        : "ltr"
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        return _vm.modalidadeComplementarSelecionada(
                                                          _vm.selectedModalidadeComplementar
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedModalidadeComplementar,
                                                      callback: function($$v) {
                                                        _vm.selectedModalidadeComplementar = $$v
                                                      },
                                                      expression:
                                                        "selectedModalidadeComplementar"
                                                    }
                                                  }),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: errors[0],
                                                      expression: "errors[0]"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "text-danger text-sm"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(errors[0]) +
                                                      "\n              "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ]),
                            (_vm.selectedModalidadeComplementar || {}).Id > 0
                              ? _c("div", { staticClass: "vx-row mt-5 pl-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "w-1/4 pl-2 pr-1" },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "flex font-semibold" },
                                        [
                                          _vm._v(
                                            "\n              Importância segurada\n              "
                                          )
                                        ]
                                      ),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          name:
                                            "Importância segurada complementar",
                                          rules: {
                                            required:
                                              (
                                                _vm.selectedModalidadeComplementar ||
                                                {}
                                              ).Id > 0
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("currency-input", {
                                                    staticClass:
                                                      "w-full vs-inputx vs-input--input large hasValue",
                                                    on: {
                                                      blur: function($event) {
                                                        return _vm.calculoValorPremio(
                                                          2
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.importanciaSeguradaComplementar,
                                                      callback: function($$v) {
                                                        _vm.importanciaSeguradaComplementar = $$v
                                                      },
                                                      expression:
                                                        "importanciaSeguradaComplementar"
                                                    }
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: errors[0],
                                                          expression:
                                                            "errors[0]"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "text-danger text-sm"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                " +
                                                          _vm._s(errors[0]) +
                                                          "\n              "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "w-1/4 pl-3 pr-2" },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "font-semibold" },
                                        [_vm._v("Inicio vigência")]
                                      ),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          name:
                                            "Inicio da vigência complementar",
                                          rules: {
                                            required:
                                              (
                                                _vm.selectedModalidadeComplementar ||
                                                {}
                                              ).Id > 0
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("datepicker", {
                                                    staticClass: "w-full",
                                                    attrs: {
                                                      language: _vm.ptBR,
                                                      format: "dd/MM/yyyy",
                                                      disabled: _vm.desabilitaInicioVigenciaComplementar(),
                                                      "disabled-dates": _vm.disabledDatesInicioComplementar()
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        return _vm.calculoDiasVigenciaComplementar()
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.vigenciaInicioComplementar,
                                                      callback: function($$v) {
                                                        _vm.vigenciaInicioComplementar = $$v
                                                      },
                                                      expression:
                                                        "vigenciaInicioComplementar"
                                                    }
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: errors[0],
                                                          expression:
                                                            "errors[0]"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "text-danger text-sm"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                " +
                                                          _vm._s(errors[0]) +
                                                          "\n              "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "w-1/4 px-2" },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "font-semibold" },
                                        [_vm._v("Prazo dias")]
                                      ),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          name: "Prazo em dias complementar",
                                          rules: {
                                            required:
                                              (
                                                _vm.selectedModalidadeComplementar ||
                                                {}
                                              ).Id > 0
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("vs-input", {
                                                    staticClass: "w-full",
                                                    attrs: { size: "large" },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.calculoDataFimVigenciaComplementar()
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.vigenciaDiasComplementar,
                                                      callback: function($$v) {
                                                        _vm.vigenciaDiasComplementar = $$v
                                                      },
                                                      expression:
                                                        "vigenciaDiasComplementar"
                                                    }
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: errors[0],
                                                          expression:
                                                            "errors[0]"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "text-danger text-sm"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                " +
                                                          _vm._s(errors[0]) +
                                                          "\n              "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "w-1/4 px-2" },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "font-semibold" },
                                        [_vm._v("Final vigência")]
                                      ),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          name: "Fim da vigência complementar",
                                          rules: {
                                            required:
                                              (
                                                _vm.selectedModalidadeComplementar ||
                                                {}
                                              ).Id > 0
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("datepicker", {
                                                    staticClass: "w-full",
                                                    attrs: {
                                                      language: _vm.ptBR,
                                                      format: "dd/MM/yyyy",
                                                      "disabled-dates": _vm.disabledDatesFimComplementar()
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        return _vm.calculoDiasVigenciaComplementar()
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.vigenciaFimComplementar,
                                                      callback: function($$v) {
                                                        _vm.vigenciaFimComplementar = $$v
                                                      },
                                                      expression:
                                                        "vigenciaFimComplementar"
                                                    }
                                                  }),
                                                  _vm.showErroData
                                                    ? _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(_vm.erroData)
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: errors[0],
                                                          expression:
                                                            "errors[0]"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "text-danger text-sm"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                " +
                                                          _vm._s(errors[0]) +
                                                          "\n              "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e()
                          ]
                        : _vm._e(),
                      _vm.podeExibirCoCorretagemMapfre
                        ? _c("div", { staticClass: "vx-row px-2" }, [
                            _c(
                              "div",
                              { staticClass: "w-1/2" },
                              [
                                _c(
                                  "vs-checkbox",
                                  {
                                    staticClass: "mb-2",
                                    model: {
                                      value: _vm.possuiCocorretagemMapfre,
                                      callback: function($$v) {
                                        _vm.possuiCocorretagemMapfre = $$v
                                      },
                                      expression: "possuiCocorretagemMapfre"
                                    }
                                  },
                                  [
                                    _c("p", { staticClass: "font-semibold" }, [
                                      _vm._v("Cocorretagem")
                                    ])
                                  ]
                                ),
                                _vm.possuiCocorretagemMapfre
                                  ? _c(
                                      "div",
                                      { staticClass: "px-5" },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "codigo quadro",
                                            rules: "number"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c("p", {}, [
                                                      _vm._v(
                                                        "Código do quadro de distribuição (opcional)"
                                                      )
                                                    ]),
                                                    _c("vs-input", {
                                                      staticClass: "w-1/2",
                                                      attrs: {
                                                        label: "",
                                                        size: "large",
                                                        maxlength: "10"
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return _vm.validarNumeros(
                                                            $event,
                                                            "codigoQuadroDistribuicao"
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.codigoQuadroDistribuicao,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.codigoQuadroDistribuicao = $$v
                                                        },
                                                        expression:
                                                          "codigoQuadroDistribuicao"
                                                      }
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: errors[0],
                                                            expression:
                                                              "errors[0]"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "text-danger text-sm"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                " +
                                                            _vm._s(errors[0]) +
                                                            "\n              "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm.podeExibirCosseguroMapfre
                        ? _c("div", { staticClass: "vx-row px-2" }, [
                            _c("div", { staticClass: "w-1/2" }, [
                              _c("p", { staticClass: "font-semibold mb-2" }, [
                                _vm._v("Cosseguro")
                              ]),
                              _c(
                                "div",
                                { staticClass: "px-5 w-1/2" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "cosseguro-mapfre-select",
                                      attrs: { placeholder: "Selecione" },
                                      model: {
                                        value: _vm.tipoCosseguro,
                                        callback: function($$v) {
                                          _vm.tipoCosseguro = $$v
                                        },
                                        expression: "tipoCosseguro"
                                      }
                                    },
                                    _vm._l(_vm.tiposCosseguros, function(
                                      cosseguro
                                    ) {
                                      return _c("el-option", {
                                        key: cosseguro.Id,
                                        attrs: {
                                          label: cosseguro.Nome,
                                          value: cosseguro.Id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.selectedModalidadePrincipal &&
                      _vm.selectedModalidadePrincipal.Id
                        ? _c("contractual-terms", {
                            attrs: {
                              modalidadeObjeto: _vm.modalidadeObjeto,
                              modalidadeComplementarObjeto:
                                _vm.modalidadeComplementarObjeto,
                              coberturasAdicionaisObjeto:
                                _vm.coberturasAdicionaisObjeto,
                              defaultTags: _vm.defaultTags,
                              tagsSeguradora: _vm.tagsSeguradora,
                              cleanTags: _vm.cleanActive
                            },
                            on: {
                              updatePropostaTermos: _vm.updatePropostaTermos
                            }
                          })
                        : _vm._e(),
                      _vm.selectedModalidadePrincipal
                        ? _c("particular-clauses-select", {
                            attrs: {
                              proposal: _vm.proposta,
                              modalityId: _vm.selectedModalidadePrincipal.Id,
                              proposalClausesRequired:
                                _vm.proposalClausesProposalRequiredValues || []
                            },
                            on: {
                              "proposal-terms-values-change":
                                _vm.particularClausesProposalTermsValuesChanged
                            },
                            model: {
                              value: _vm.selectedParticularClauses,
                              callback: function($$v) {
                                _vm.selectedParticularClauses = $$v
                              },
                              expression: "selectedParticularClauses"
                            }
                          })
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "aside",
                    { staticClass: "vx-col w-4/12 pl-16" },
                    [
                      _c("div", { staticClass: "vx-row" }, [
                        _c("div", { staticClass: "vx-col w-full" }, [
                          _c("p", { staticClass: "font-semibold" }, [
                            _vm._v("Coberturas adicionais")
                          ]),
                          (_vm.coberturas || []).length > 0
                            ? _c(
                                "ul",
                                { staticClass: "mt-8" },
                                _vm._l(_vm.coberturas, function(
                                  cobertura,
                                  index
                                ) {
                                  return _c(
                                    "li",
                                    {
                                      key: cobertura.id,
                                      class: { "pt-5": index > 0 }
                                    },
                                    [
                                      _c(
                                        "vs-checkbox",
                                        {
                                          attrs: { "vs-value": cobertura.Id },
                                          on: {
                                            change: function($event) {
                                              return _vm.atualizaCoberturas()
                                            }
                                          },
                                          model: {
                                            value: _vm.coberturasAdicionais,
                                            callback: function($$v) {
                                              _vm.coberturasAdicionais = $$v
                                            },
                                            expression: "coberturasAdicionais"
                                          }
                                        },
                                        [_vm._v(_vm._s(cobertura.Name))]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          (_vm.coberturas || []).length === 0
                            ? _c("p", { staticClass: "mt-3" }, [
                                _vm._v(
                                  "\n            NÃO PERMITE CONTRATAÇÃO DE COBERTURAS ADICONAIS PARA ESTA\n            MODALIDADE\n          "
                                )
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _vm.canViewLimits
                        ? _c("div", { staticClass: "vx-row mt-12" }, [
                            _c(
                              "div",
                              { staticClass: "vx-col w-full" },
                              [
                                _c("p", { staticClass: "font-semibold" }, [
                                  _vm._v("Prêmio Estimado")
                                ]),
                                _c("currency-input", {
                                  staticClass:
                                    "w-full vs-inputx vs-input--input large hasValue",
                                  attrs: { value: _vm.premio, disabled: "" }
                                }),
                                _c(
                                  "vs-alert",
                                  {
                                    staticClass: "h-auto mt-2",
                                    attrs: { color: "warning" }
                                  },
                                  [
                                    _vm._v(
                                      "\n            Prêmio estimado. Este valor poderá sofrer alterações durante a\n            avaliação de risco.\n          "
                                    )
                                  ]
                                ),
                                (_vm.selectedModalidadePrincipal || {})
                                  .CanUseModalityTax
                                  ? _c(
                                      "vs-alert",
                                      { staticClass: "h-auto mt-2" },
                                      [
                                        _vm._v(
                                          "\n            Esta modalidade possui taxa diferenciada, sendo a mesma\n            "
                                        ),
                                        _c("b", [
                                          _c("u", [
                                            _vm._v(_vm._s(_vm.agravationType))
                                          ])
                                        ]),
                                        _vm._v(
                                          "\n            com relação às taxas originais do Grupo de Modalidades em\n            "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              (
                                                _vm.selectedModalidadePrincipal ||
                                                {}
                                              ).ModalityTaxValue
                                            ) + "%"
                                          )
                                        ]),
                                        _vm._v(".\n          ")
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      (_vm.selectedModalidadePrincipal || {}).CanHaveBeneficiary
                        ? [
                            _c("dados-beneficiario", {
                              attrs: {
                                allowedPF: (
                                  _vm.selectedModalidadePrincipal || {}
                                ).CanHaveBeneficiaryPF,
                                podeHabilitarCadastroNomeSocialBeneficiario:
                                  _vm.podeHabilitarCadastroNomeSocialBeneficiario
                              }
                            })
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col w-full mt-8 flex flex-row-reverse footer-postion"
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-4",
                          attrs: {
                            color: "success",
                            type: "filled",
                            disabled: invalid
                          },
                          on: {
                            click: function($event) {
                              _vm.salvarProposta(), (_vm.label = "Proposta")
                            }
                          }
                        },
                        [_vm._v("Enviar para seguradora")]
                      )
                    ],
                    1
                  ),
                  _c("poposal-step-rules-modal", {
                    attrs: {
                      showModal: _vm.enviarParaSeguradoraModal,
                      stepList: _vm.getSteps,
                      showErrorButton: _vm.showTimelineErrorButton,
                      showSuccessButton:
                        !_vm.showTimelineErrorButton &&
                        !_vm.showRequiredDocumentsButton &&
                        !!_vm.proposta.UniqueId,
                      showPendingButton: _vm.showRequiredDocumentsButton,
                      showLeaveButton: !(
                        _vm.showTimelineButton && _vm.proposta.UniqueId
                      ),
                      showButtons: _vm.showActionButtons,
                      showExeption: _vm.showExeptionButton,
                      labelModal: _vm.label,
                      labelErrorButton: "Seguir para timeline",
                      labelSuccessButton: "Seguir para emissão"
                    },
                    on: {
                      onClickSuccessButton: function($event) {
                        return _vm.goToStep4()
                      },
                      onClickErrorButton: function($event) {
                        return _vm.goToTimeline()
                      },
                      onClickCloseButton: function($event) {
                        return _vm.goToDashboard()
                      },
                      onClickLeaveButton: function($event) {
                        return _vm.closeModal()
                      },
                      onClickPendingButton: function($event) {
                        return _vm.sendDocuments()
                      },
                      setActionModal: _vm.enviarParaSeguradoraModal
                    }
                  }),
                  _c(
                    "vs-popup",
                    {
                      staticClass: "modalCancelation",
                      attrs: {
                        active: _vm.showModalCancelationProposal,
                        title: "",
                        "button-close-hidden": true
                      },
                      on: {
                        "update:active": function($event) {
                          _vm.showModalCancelationProposal = $event
                        }
                      }
                    },
                    [
                      _c("div", [
                        _c("h3", [
                          _vm._v(
                            "O valor da proposta excede o limite aprovado."
                          )
                        ]),
                        _c("br"),
                        _c("h6", [
                          _vm._v(
                            "Deseja revisar limite deste Tomador para continuar com a proposta?"
                          )
                        ])
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "mt-8 px-20 button-default",
                              attrs: { color: "primary", type: "filled" },
                              on: {
                                click: function($event) {
                                  return _vm.continuarProposta()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          Continuar com a proposta\n        "
                              )
                            ]
                          ),
                          _c(
                            "vs-button",
                            {
                              staticClass: "mt-8 ml-3",
                              staticStyle: {
                                "background-color": "#cb2020 !important",
                                float: "inline-end"
                              },
                              attrs: { type: "filled" },
                              on: {
                                click: function($event) {
                                  return _vm.cancelarProposta()
                                }
                              }
                            },
                            [_vm._v("\n          Cancelar proposta\n        ")]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  ((_vm.detalhamento || {}).RequestDocuments || []).length
                    ? [
                        _c(
                          "div",
                          { staticClass: "documentos" },
                          [
                            _c("required-documents", {
                              attrs: {
                                proposal: _vm.proposta,
                                detalhamento: _vm.detalhamento,
                                propostaUniqueId: _vm.proposalUniqueId,
                                showModal: _vm.popupDetalhamento,
                                showFollowUp: true
                              },
                              on: {
                                closeModal: _vm.closeModalDocuments,
                                dismissModal: _vm.closeModalDocuments,
                                updateDocs: _vm.updateDocs
                              }
                            })
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }