<template>
  <div class="dados-seguradora">
    <div class="vx-row px-8 mb-5">
      <h4 class="vx-col w-full flex items-center font-semibold">
        <span class="block"
          >Digite o CPF/CNPJ ou Nome/Razão Social para buscar um Segurado.</span
        >
        <!-- <i class="material-icons">info</i> -->
      </h4>
      <div class="vx-col w-full mt-5">
        <multiselect
          v-model="cnpjRazaoSocial"
          class="select-options w-full"
          id="buscar-segurado"
          label="Name"
          placeholder="Digite o CNPJ ou Razão Social para buscar um Segurado"
          open-direction="bottom"
          :options="dadosBuscaSegurado"
          :searchable="true"
          :loading="isLoading"
          :internal-search="false"
          :clear-on-select="false"
          :options-limit="300"
          :limit="3"
          :max-height="600"
          :show-no-options="false"
          :show-no-results="true"
          :hide-selected="true"
          @search-change="buscarSegurado"
          select-label=""
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc"
              ><span
                class="option__title text-sm truncate w-full inline-block md:text-base lg:text-x1"
                >{{ props.option.Name }}</span
              ></span
            >
          </template>

          <template slot="option" slot-scope="props">
            <div
              class="option__desc"
              @click="validarSegurado(props.option.DocumentNumber, cnpjTomador)"
            >
              <span class="option__title">
                <b>{{ props.option.Name }}</b>
              </span>
              <p class="mt-3" v-if="props.option.DocumentNumber">
                <the-mask
                  class="w-full the-mask"
                  :mask="[
                    props.option.DocumentIsCPF
                      ? '###.###.###-##'
                      : '##.###.###/####-##'
                  ]"
                  disabled="true"
                  :masked="true"
                  :value="props.option.DocumentNumber"
                />
              </p>
              <p class="mt-3" v-if="props.option.Description">
                {{ props.option.Description }}
              </p>
            </div>
          </template>
          <template slot="clear" slot-scope="props">
            <div
              class="multiselect__clear"
              @mousedown.prevent.stop="clearAll(props.search)"
            ></div>
          </template>
          <span slot="noResult"
            >Não foi encontrado nenhum segurado na base.</span
          >
        </multiselect>
      </div>
    </div>
    <div v-if="!!dadosSegurado.CpfCnpj">
      <div class="vx-row px-8 mb-5">
        <div class="vx-col md:w-1/2 w-full mt-5">
          <p>
            {{ dadosSegurado.Entity == "F" ? "Nome Completo" : "Razão Social" }}
          </p>
          <vs-input
            disabled
            v-model="dadosSegurado.Name"
            class="w-full"
            size="large"
          />
        </div>
        <div class="vx-col md:w-1/2 w-full mt-5">
          <p>{{ dadosSegurado.Entity == "F" ? "CPF" : "CNPJ" }}</p>
          <the-mask
            class="w-full input-the-mask"
            :mask="[
              dadosSegurado.Entity == 'F'
                ? '###.###.###-##'
                : '##.###.###/####-##'
            ]"
            disabled="true"
            :masked="true"
            :value="dadosSegurado.CpfCnpj"
          />
        </div>
      </div>
      <div class="vx-row px-8 mb-5">
        <div class="vx-col  w-full mt-5" v-if="dadosSegurado.Entity === 'J'">
          <p>
            TÍTULO DO ESTABELECIMENTO  (Nome Fantasia)
          </p>
          <vs-input
            disabled
            v-model="dadosSegurado.NomeSocial"
            class="w-full"
            size="large"
          />
        </div>
        <div
          class="vx-col  w-full mt-5"
          v-else-if="
            dadosSegurado.Entity === 'F' && podeHabilitarCadastroNomeSocial  && dadosSegurado.NomeSocial
          "
        >
          <p>
            Nome Social
          </p>
          <vs-input
            disabled
            v-model="dadosSegurado.NomeSocial"
            class="w-full"
            size="large"
          />
        </div>
        <div class="vx-col  w-full mt-5" v-if="dadosSegurado.Entity === 'J'">
          <FantasySegurado
            v-if="dadosSegurado"
            :segurado="dadosSegurado"
          ></FantasySegurado>
        </div>
        <div
          class="vx-col  w-full mt-5"
          v-else-if="
            dadosSegurado.Entity === 'F' && podeHabilitarCadastroNomeSocial
          "
        >
          <FantasySegurado
            v-if="dadosSegurado"
            :segurado="dadosSegurado"
          ></FantasySegurado>
        </div>
      </div>

      <div class="vx-row px-8 mb-5">
        <div class="vx-col md:w-full">
          <p>Endereço Principal</p>
          <vs-input
            disabled
            :value="enderecoPrincipal.Address"
            class="w-full"
            size="large"
          />
        </div>
      </div>

      <div class="m-8" v-if="(listaEnderecos || []).length > 0">
        <h3 class="mb-8 text-base md:text-lg lg:text-2x1 xl:text-3x1">
          Caso deseje utilizar outro endereço para o Segurado, selecione abaixo
          ou adicione um.
        </h3>
        <vs-card v-for="(endereco, index) in listaEnderecos" :key="endereco.Id">
          <div class="flex items-center p-5">
            <vs-checkbox
              @change="toggleEndereco(endereco)"
              v-model="enderecoSelecionado[endereco.Id]"
              class="w-full"
            >
              <div class="ml-2 mr-3 flex flex-col flex-grow">
                <p class="text-base font-semibold">{{ endereco.Address }}</p>
              </div>
            </vs-checkbox>
            <vs-button
              radius
              color="primary"
              type="flat"
              @click="removeEndereco(endereco.Id, index)"
              size="large"
              icon="delete"
            ></vs-button>
          </div>
        </vs-card>
      </div>
      <div class="p-8 flex flex-row-reverse">
        <vs-button
          type="flat"
          icon="add_circle"
          :icon-after="true"
          @click="incluirNovoEnderecoSegurado()"
        >
          Incluir Endereço adicional</vs-button
        >
      </div>
    </div>
    <vs-popup
      class="endereco"
      title=""
      :buttonCloseHidden="addressError"
      :active.sync="popupAddEndereco"
    >
      <h3 class="pl-4 pb-6">Novo Endereço</h3>
      <vs-alert
        v-if="addressError"
        color="warning"
        class="pl-4 h-auto mt-2 mb-3"
      >
        Não encontramos nenhum endereço vinculado. Por favor, adicione para
        continuar.
      </vs-alert>
      <div class="p-4 pt-0">
        <div class="vx-col">
          <ValidationProvider name="CEP" rules="required" v-slot="{ errors }">
            <vs-input
              label="CEP"
              size="large"
              class="w-full"
              v-model="cep"
              v-mask="'#####-###'"
              v-debounce:1s="buscarCep"
            />
            <span class="text-danger " v-show="errors[0]">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </div>

        <template v-if="novoEndereco.ZipCode">
          <div class="mt-5 flex flex-wrap justify-around">
            <div class="vx-col pr-1 w-3/4 md:pr-4">
              <ValidationProvider
                name="Logradouro"
                rules="required"
                v-slot="{ errors }"
              >
                <vs-input
                  label="Logradouro"
                  size="large"
                  class="w-full"
                  v-model="novoEndereco.AddressName"
                />

                <span class="text-danger" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>

            <div class="vx-col w-1/4">
              <ValidationProvider
                name="Número"
                rules="required"
                v-slot="{ errors }"
              >
                <vs-input
                  label="Número"
                  size="large"
                  class="w-full"
                  v-model="novoEndereco.Number"
                />

                <span class="text-danger" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>

          <div class="mt-5 flex flex-wrap justify-around">
            <div class="vx-col pr-1 w-full md:w-3/5 md:pr-4">
              <vs-input
                label="Complemento"
                size="large"
                class="w-full"
                v-model="novoEndereco.Complement"
              />
            </div>

            <div class="vx-col mt-5 w-full md:w-2/5 md:mt-0">
              <ValidationProvider
                name="Bairro"
                rules="required"
                v-slot="{ errors }"
              >
                <vs-input
                  label="Bairro"
                  size="large"
                  class="w-full"
                  v-model="novoEndereco.Neighborhood"
                />
                <span class="text-danger" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>

          <div class="mt-5 flex flex-wrap justify-around md:w-3/5">
            <div class="vx-col pr-1 w-full md:w-1/2 md:pr-4">
              <ValidationProvider
                name="Cidade"
                rules="required"
                v-slot="{ errors }"
              >
                <vs-input
                  label="Cidade"
                  size="large"
                  class="w-full"
                  v-model="novoEndereco.CityName"
                />
                <span class="text-danger" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="vx-col mt-5 w-full md:w-1/2 md:mt-0">
              <ValidationProvider
                name="Estado"
                rules="required"
                v-slot="{ errors }"
              >
                <vs-input
                  label="Estado"
                  size="large"
                  class="w-full"
                  v-model="novoEndereco.StateProvinceName"
                />

                <span class="text-danger" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>

          <div class="p-4 mt-5 flex flex-row-reverse w-full">
            <vs-button
              @click="salvarendereco(novoEndereco)"
              :disabled="!podeAdicionarEndereco"
              >Salvar Endereço</vs-button
            >
          </div>
        </template>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mask, TheMask } from "vue-the-mask";
import { mapGetters, mapMutations, mapActions } from "vuex";
import * as Utils from "@/utils/utils";
import FantasySegurado from "@/components/fantasy-name/corretoraSegurado.vue";
import axiosInstance from "@/axios";
export default {
  name: "dados-segurado",
  props: [
    "cnpjTomador",
    "uniqueId",
    "idTomador",
    "podeHabilitarCadastroNomeSocial"
  ],
  directives: { mask },
  components: {
    Multiselect,
    TheMask,
    FantasySegurado
  },
  data() {
    return {
      _refreshDelayPromise: undefined,
      isLoading: false,
      selectedAddressId: undefined,
      cnpj: "",
      novoNomeFantasia: "",
      cnpjRazaoSocial: "",
      dadosBuscaSegurado: [],
      enderecos: [],
      novoEndereco: {},
      popupAddEndereco: false,
      addressError: false,
      enderecoSelecionado: [],
      cep: ""
    };
  },
  watch: {
    proposta: {
      immediate: true,
      handler(val, oldVal) {
        if ((this.proposta || {}).InsuredLocationId > 0)
          this.enderecoSelecionado[
            (this.proposta || {}).InsuredLocationId
          ] = true;
      }
    }
  },
  computed: {
    ...mapGetters("proposta-module", ["proposta"]),
    dadosSegurado: {
      get() {
        const result = (this.proposta || {}).Insured || {};
        return result;
      }
    },
    enderecoPrincipal: {
      get() {
        if (
          this.dadosSegurado !== null &&
          this.dadosSegurado.Locations !== null &&
          this.dadosSegurado.Locations.length > 0
        ) {
          const foundMain =
            this.dadosSegurado.Locations > 1
              ? this.dadosSegurado.Locations.find(loc => loc.IsMain === true)
              : this.dadosSegurado.Locations[0] || {};
          this.selectedAddressId = (foundMain || {}).Id;
          if (foundMain.AddressName == "" || foundMain.length == 0) {
            this.popupAddEndereco = true;
            this.addressError = true;
          }
          return foundMain || {};
        }
        this.popupAddEndereco = true;
        this.addressError = true;
        return {};
      }
    },
    listaEnderecos: {
      get() {
        const result = ((this.dadosSegurado || {}).Locations || []).filter(
          x => x.IsMain !== true
        );
        return result;
      }
    },
    podeAdicionarEndereco() {
      return (
        this.novoEndereco.AddressName &&
        this.novoEndereco.Number &&
        this.novoEndereco.Neighborhood &&
        this.novoEndereco.ZipCode &&
        this.novoEndereco.CityName &&
        this.novoEndereco.StateProvinceName
      );
    }
  },
  methods: {
    ...mapMutations("proposta-module", [
      "updateSeguradoProposta",
      "updatePropostaEnderecosSegurado",
      "updatePropostaEnderecoSegurado",
      "updateFantasyName"
    ]),
    ...mapActions("proposta-module", [
      "validaSeguradoPorCnpj",
      "buscaNomeFantasiaPorId",
      "buscaSeguradoPorTexto"
    ]),
    ...mapActions("location-module", ["searchCEP"]),

    clearAll() {
      this.dadosBuscaSegurado = [];
    },
    toggleEndereco(endereco) {
      /****/
      Object.entries(this.enderecoSelecionado).forEach(key => {
        if (key[0] != endereco.Id) {
          this.enderecoSelecionado[key[0]] = false;
        }
      });

      const checked = this.enderecoSelecionado[endereco.Id];
      if (checked) this.updatePropostaEnderecoSegurado(endereco);
      else this.updatePropostaEnderecoSegurado(this.enderecoPrincipal);
    },
    incluirNovoEnderecoSegurado() {
      this.cep = "";
      this.novoEndereco = {};
      this.popupAddEndereco = true;
    },
    async validarSegurado(cnpj, cnpjTomador) {
      if (!cnpj) return;

      this.$appInsights.trackEvent({ name: "inserir-segurado-proposta", properties: { place: "proposta-passo-2", action: "button-click", slug: "inserir-segurado-proposta-passo-2" }});
      await this.$onpoint.loading( async () => {
        const data = {
          InsuredDocument: Utils.removeSpecialChars(cnpj),
          PolicyHolderCnpj: cnpjTomador
        };
        return await this.validaSeguradoPorCnpj(data)
          .then(response => {
            // Necessário para salvar backup da Razão social, caso usuário escolha em apagar ou desclickar no item
            this.updateFantasyName(response.Name);
          })
          .catch(errors => {
            this.novoEndereco = {};
            this.$onpoint.errorModal(errors.response.data.Errors);
          })
          .finally(() => {
            this.isLoading = false;
            window.scrollTo(0, 0);
          });
      });
    },

    buscarSegurado(query) {
      if (!query || query.length < 3) {
        return this.clearAll();
      }
      if (this._refreshDelayPromise) {
        clearTimeout(this._refreshDelayPromise);
      }
      this._refreshDelayPromise = setTimeout(
        async function() {
          this.isLoading = true;
          this.dadosBuscaSegurado = [];
          await this.buscaSeguradoPorTexto(Utils.removeSpecialChars(query))
            .then(response => {
              this.isLoading = false;
              if (!response) response = [];
              this.dadosBuscaSegurado = response;
            })
            .catch(() => {
              this.$onpoint.errorModal(
                "Ocorreu um erro ao tentar buscar os dados do segurado."
              );
            })
            .finally(() => {
              this.isLoading = false;
            });
        }.bind(this),
        500
      );
    },
    buscarCep() {
      const cep = Utils.removeSpecialChars(this.cep);
      if (!cep || cep.length < 8) return;
      this.$onpoint.loading(() => {
        return axiosInstance
          .get(`/api/Location/SearchCEP?cep=${cep}`)
          .then(response => {
            this.novoEndereco = response.data.Response;
          })
          .catch(error => {
            this.cep = "";
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    removeEndereco(id) {
      this.$onpoint.loadingModal("Processando sua solicitação dados", () => {
        return axiosInstance
          .delete(`/api/Insured/RemoveInsuredLocation?locationId=${id}`)
          .then(() => {
            const enderecoDeletado = (
              (this.dadosSegurado || {}).Locations || []
            ).find(x => x.Id === id);
            const copiaEnderecos = (
              (this.dadosSegurado || {}).Locations || []
            ).filter(x => x.Id !== id);

            const checked = this.enderecoSelecionado[id];
            if (checked) {
              this.enderecoSelecionado[id] = false;
              this.toggleEndereco(enderecoDeletado);
            }
            this.updatePropostaEnderecosSegurado(copiaEnderecos);
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.Message);
          });
      });
    },

    async salvarendereco() {
      const enderecoNovo = {
        ...this.novoEndereco,
        PersonId: this.dadosSegurado.Id
      };
      return await this.$onpoint.loading(async() => {
        if (
          this.dadosSegurado.Locations.length == 0 ||
          this.dadosSegurado.Locations == null ||
          this.enderecoPrincipal.AddressName == ""
        ) {
          enderecoNovo.IsMain = true;
        }
        return await axiosInstance({
          method: "post",
          url: "/api/Insured/SaveInsuredLocation",
          data: enderecoNovo
        })
          .then(response => {
            this.updatePropostaEnderecosSegurado(response.data.Response);
            this.cep = "";
            this.novoEndereco = {};
            this.popupAddEndereco = false;
            this.addressError = false;
            this.$onpoint.successModal("Endereço cadastrado com sucesso.");
          })
          .catch(response => {
            this.$onpoint.errorModal(response.data.Errors);
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
h4 {
  color: rgba(var(--sv-dark), 1);

  i {
    color: rgba(var(--vs-warning), 1);
    display: block;
    font-size: 0.8em;
  }
}

.link {
  float: right;
}
.endereco {
  z-index: 52000;
}
.custom-tab-fantasy .icon-header i {
  color: rgba(var(--vs-primary), 1) !important;
}
</style>
