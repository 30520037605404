import { render, staticRenderFns } from "./DadosSegurado.vue?vue&type=template&id=d8f0101e&scoped=true&"
import script from "./DadosSegurado.vue?vue&type=script&lang=js&"
export * from "./DadosSegurado.vue?vue&type=script&lang=js&"
import style0 from "./DadosSegurado.vue?vue&type=style&index=0&id=d8f0101e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8f0101e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\OnPoint-Front\\OnPoint-Front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d8f0101e')) {
      api.createRecord('d8f0101e', component.options)
    } else {
      api.reload('d8f0101e', component.options)
    }
    module.hot.accept("./DadosSegurado.vue?vue&type=template&id=d8f0101e&scoped=true&", function () {
      api.rerender('d8f0101e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/propostas/components/criacao/DadosSegurado.vue"
export default component.exports