import { render, staticRenderFns } from "./DadosBeneficiario.vue?vue&type=template&id=0f833af1&scoped=true&"
import script from "./DadosBeneficiario.vue?vue&type=script&lang=js&"
export * from "./DadosBeneficiario.vue?vue&type=script&lang=js&"
import style0 from "./DadosBeneficiario.vue?vue&type=style&index=0&id=0f833af1&lang=scss&scoped=true&"
import style1 from "./DadosBeneficiario.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./DadosBeneficiario.vue?vue&type=style&index=2&id=0f833af1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f833af1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\OnPoint-Front\\OnPoint-Front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0f833af1')) {
      api.createRecord('0f833af1', component.options)
    } else {
      api.reload('0f833af1', component.options)
    }
    module.hot.accept("./DadosBeneficiario.vue?vue&type=template&id=0f833af1&scoped=true&", function () {
      api.rerender('0f833af1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/propostas/components/criacao/DadosBeneficiario.vue"
export default component.exports